<template>
    <div>
        <div class="d-block" v-bind:style="{ 'min-height' : '75vh' }">
            <div class="container header-fixed">
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <div class="d-block pt-lg-5 pt-md-5 pt-3">
                            <h1>Program</h1>
                        </div>
                        <!-- Search -->
                        <div class="d-flex align-items-center mb-0 d-flex-mobile">
                            <div class="input-group input-group-merge mb-md-0 mb-3 flex-grow-1">
                                <input v-model="keyword" type="search" class="form-control form-control-prepended search" placeholder="Cari formulir disini..." v-on:keyup.enter="getData">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                        <span class="fe fe-search"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Formulir List Front -->
                        <div class="section pb-3">
                            <div class="d-flex align-items-center justify-content-between mb-3 pb-3 border-bottom">
                                <h4 class="m-0">Menampilkan {{listData.length}} Data</h4>
                            </div>

                            <div v-if="fetching">
                                <div class="row hide-mobile">
                                    <div v-for="(item,index) in [1,2,3,4]" :key="index" class="col-md-3 mb-3 col-load">
                                        <div class="d-block pb-3 border-bottom h-100 link-btn-white">
                                            <div class="d-flex flex-column justify-content-between h-100">
                                                <div class="flex-grow-1">
                                                    <div class="card-img-news mb-3 add-skeleton-load delay-1"></div>
                                                    <div class="skeleton-bar">
                                                        <div class="sb-lg mb-2"></div>
                                                        <div class="sb-md mb-2"></div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div class="skeleton-bar">
                                                        <div class="sb-sm sb-thin mb-2"></div>
                                                        <div class="sb-lg sb-bold"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row show-mobile">
                                    <div v-for="(item,index) in [1,2]" :key="index" class="col-md-3 mb-3 col-load">
                                        <div class="d-block pb-3 border-bottom h-100 link-btn-white">
                                            <div class="d-flex flex-column justify-content-between h-100">
                                                <div class="flex-grow-1">
                                                    <div class="card-img-news mb-3 add-skeleton-load delay-1"></div>
                                                    <div class="skeleton-bar">
                                                        <div class="sb-lg mb-2"></div>
                                                        <div class="sb-md mb-2"></div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div class="skeleton-bar">
                                                        <div class="sb-sm sb-thin mb-2"></div>
                                                        <div class="sb-lg sb-bold"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="!fetching">
                                <div v-if="listData.length == 0" class="empty-content" data-empty-title="Daftar Program tidak tersedia">
                                    <div class="row hide-mobile">
                                        <div v-for="(item,index) in [1,2,3,4]" :key="index" class="col-md-3 mb-3 col-load">
                                            <div class="d-block pb-3 border-bottom h-100 link-btn-white">
                                                <div class="d-flex flex-column justify-content-between h-100">
                                                    <div class="flex-grow-1">
                                                        <div class="card-img-news mb-3 add-skeleton-load skeleton-empty delay-1"></div>
                                                        <div class="skeleton-bar skeleton-empty">
                                                            <div class="sb-lg mb-2"></div>
                                                            <div class="sb-md mb-2"></div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="skeleton-bar skeleton-empty">
                                                            <div class="sb-sm sb-thin mb-2"></div>
                                                            <div class="sb-lg sb-bold"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row show-mobile">
                                        <div v-for="(item,index) in [1,2]" :key="index" class="col-md-3 mb-3 col-load">
                                            <div class="d-block pb-3 border-bottom h-100 link-btn-white">
                                                <div class="d-flex flex-column justify-content-between h-100">
                                                    <div class="flex-grow-1">
                                                        <div class="card-img-news mb-3 add-skeleton-load skeleton-empty delay-1"></div>
                                                        <div class="skeleton-bar skeleton-empty">
                                                            <div class="sb-lg mb-2"></div>
                                                            <div class="sb-md mb-2"></div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="skeleton-bar skeleton-empty">
                                                            <div class="sb-sm sb-thin mb-2"></div>
                                                            <div class="sb-lg sb-bold"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="listData.length > 0" class="row">
                                    <div v-for="(item, index) in listData" :key="index" class="col-md-3 mb-3">
                                        <router-link v-bind:to="'/program/detail/' + item.id" class="d-block pb-3 border-bottom h-100 link-btn-white">
                                            <div class="d-flex flex-column justify-content-between h-100">
                                                <div class="flex-grow-1">
                                                    <div class="lozad card-img-news mb-3" v-bind:style="{ 'background-image': 'url(' + item.cover + ')' }"> >
                                                    </div>
                                                    <h4 class="mb-4 text-dark">{{item.title}}</h4>
                                                </div>
                                                <div>
                                                    <div class="d-block text-dark small mb-2">{{item.date}} - {{item.author}}</div>
                                                    <div class="btn btn-block btn-sm btn-white">Lihat Program</div>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                            </div>

                            <div class="d-none text-center py-4">
                                <button class="btn btn-primary-light px-4">Muat Lebih Banyak</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    // @ is an alias to /src
    import lozad from 'lozad';

    export default {
        name: 'Beranda',
        components: {

        },
        metaInfo: {
            title: 'SchoolPro',
            titleTemplate: '%s - Program'
        },
        data() {
            return {
                search: "",
                listData: [],
                pageOfItems: [],
                fetching: true,
                keyword: ''
            }
        },
        mounted() {
            const observer = lozad();
            observer.observe();
            setTimeout(() => this.scrollFix(this.$route.hash), 1);
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                this.listData = [];
                this.fetching = true;
                let uri = this.$apiconfig + 'website/program/list';
                this.$http.get(uri, {
                    params: {
                        keyword: this.keyword
                    }
                }).then(res => {
                    let getResponse = res.data;
                    if (getResponse.status) {
                        this.listData = getResponse.data;
                    } else {
                        this.listData = [];
                    }
                    this.fetching = false;
                });
            },
            scrollFix: function(hashbang) {
                location.hash = hashbang;

                // remove fragment as much as it can go without adding an entry in browser history:
                window.location.replace("#");

                // slice off the remaining '#' in HTML5:    
                if (typeof window.history.replaceState == 'function') {
                    history.replaceState({}, '', window.location.href.slice(0, -1));
                }

            }
        }
    }
</script>